import React from 'react';
import styles from './AboutUs.module.css';
import slider1 from '../assets/slider1.webp';
import slider2 from '../assets/slider2.webp';
import slider3 from '../assets/slider3.webp';
import Carousel from './Carousel';

const AboutUs = () => {
    const slides = [
        slider1,
        slider2,
        slider3,
    ];

    return (
        <div className={styles.aboutUs}>
            <div className={styles.imageSection}>
                <Carousel slides={slides} />
            </div>
            <div className={styles.textSection}>
                <h2>About WantedLucky.com</h2>
                <p>
                    Welcome to WantedLucky.com, your one-stop shop for high-quality kitchenware and culinary essentials. We pride ourselves on offering a curated selection of products that meet the needs of both amateur cooks and seasoned chefs alike.
                </p>
                <p>
                    At WantedLucky.com, we understand the importance of having the right tools in the kitchen. Whether you're looking for durable cookware, stylish dinnerware, or innovative kitchen gadgets, we've got you covered. Our mission is to inspire creativity and enhance your culinary experience through our carefully chosen products.
                </p>
                <p>
                    Explore our website to discover our latest collections and exclusive offers. Join our community of kitchen enthusiasts and elevate your cooking skills with WantedLucky.com today!
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
