import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Terms.module.css'; // Assuming you have a CSS module for styling

const Terms = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.termsInfo}>
      <h1>TERMS OF SERVICE</h1>
      <p>Welcome to {siteName}! By accessing and using our website, you agree to comply with the following terms and conditions. If you do not agree, please do not use our site.</p>
      
      <h2>1. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>All content on our website, including text, graphics, logos, icons, images, and videos, is the property of {siteName} and is protected by copyright and intellectual property laws. You may not use, modify, distribute, or reproduce any content without our explicit written permission.</p>
      
      <h2>2. PERMISSIBLE USE</h2>
      <p>Our website is intended for personal, non-commercial use only. You agree not to engage in activities that may compromise the functionality or security of our site, including but not limited to introducing malware or attempting unauthorized access to our systems.</p>
      
      <h2>3. INFORMATION ACCURACY</h2>
      <p>We strive for accuracy in all product information provided on our site. However, we do not guarantee that all details are error-free. We reserve the right to correct any inaccuracies in product descriptions, prices, or availability without prior notice.</p>
      
      <h2>4. PRICING AND TRANSACTIONS</h2>
      <p>Product prices listed on our site are subject to change. All transactions are conducted in the currency specified during checkout. We offer secure payment options, and the accepted methods will be clearly outlined.</p>
      
      <h2>5. ORDERS AND CANCELLATIONS</h2>
      <p>When you place an order with us, you are making an offer to purchase products based on the terms specified. We reserve the right to accept or reject orders for various reasons, such as stock availability or payment issues. If we cancel your order, you will be promptly notified and refunded.</p>
      
      <h2>6. SHIPPING AND RETURNS</h2>
      <p>We provide shipping services with varying fees and delivery times depending on the method and destination. Please refer to our comprehensive Shipping and Returns Policy for detailed information on these processes.</p>
      
      <h2>7. EXTERNAL LINKS</h2>
      <p>Our website may include links to external sites not operated by {siteName}. We do not endorse or have control over the content or privacy practices of these sites. It is your responsibility to review their terms and policies before interacting with them.</p>
      
      <h2>8. LIMITATION OF LIABILITY</h2>
      <p>{siteName} and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website or reliance on the information provided. This limitation applies to the fullest extent permitted by applicable law.</p>
      
      <h2>9. ACCEPTANCE OF TERMS</h2>
      <p>These terms may be revised from time to time without prior notice. Your continued use of our site following any updates constitutes acceptance of the revised terms. If you have questions about these terms, please contact us at <a href={`mailto:info@${siteName.toLowerCase()}`}>info@{siteName}</a>.</p>
    </div>
  );
};

export default Terms;
