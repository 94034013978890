import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const siteInfo = {
    siteName: "WantedLucky.com",
    slogan: "Bringing Luck to Your Doorstep",
    info: "At WantedLucky.com, we specialize in unique and high-quality kitchenware that brings a touch of luck and charm to your home.",
    thankYouProduct: "Thank you for your interest in our product. We hope it brings you good fortune and joy!",
    thankYouSubscription: "Thank you for subscribing to WantedLucky.com. Stay tuned for lucky deals and updates!",
    thankYouOrder: "Thank you for your order. Your lucky items are on their way to you!",
  };

  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

// Create a custom hook to use the SiteContext
export const useSite = () => {
  return useContext(SiteContext);
};
