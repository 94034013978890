import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';

const products = [
  { 
    id: 1, 
    name: 'White Berries Mug', 
    price: '12', 
    image: image1, 
    badge: 'New', 
    description: 'This White Berries Mug is perfect for your morning coffee or tea. Its elegant design and sturdy construction make it a must-have for your kitchen collection.' 
  },
  { 
    id: 2, 
    name: 'Ceramic Berry Bowl', 
    price: '25', 
    image: image2, 
    badge: 'New', 
    description: 'Our Ceramic Berry Bowl is an excellent choice for those who value quality and style. It\'s ideal for serving fresh fruits or as a charming decorative piece.' 
  },
  { 
    id: 3, 
    name: 'Oval Colander Plate', 
    price: '18', 
    image: image3, 
    badge: 'New', 
    description: 'The Oval Colander Plate combines functionality and elegance. Perfect for draining and serving, it\'s a versatile addition to any kitchen.' 
  },
  { 
    id: 4, 
    name: 'Wooden Spoon Colander', 
    price: '15', 
    image: image4, 
    badge: 'Sale', 
    description: 'Our Wooden Spoon Colander is crafted from high-quality wood, offering both durability and a rustic charm. Great for everyday use and special occasions.' 
  },
  { 
    id: 5, 
    name: 'French Cheese Mold Set', 
    price: '30', 
    image: image5, 
    badge: 'Sale', 
    description: 'This French Cheese Mold Set is perfect for cheese enthusiasts. It provides superior performance and adds a touch of elegance to your cheese-making process.' 
  },
  { 
    id: 6, 
    name: 'Senbird Tea Strainer', 
    price: '20', 
    image: image6, 
    badge: 'Sale', 
    description: 'The Senbird Tea Strainer offers high quality and excellent performance. Ideal for professionals and tea lovers, it ensures a perfect brew every time.' 
  },
];

export default products;
